import React from "react";
import { Helmet } from "react-helmet";
// import "../styles.css";

function Home() {
  return (
    <div className="App">
        <Helmet>
          <base href="/"></base>
          <link href="styles.css" type="text/css"></link>
        {/* <link rel="stylesheet" type="text/css" href="login.css" /> */}
      </Helmet>
      <h1>Home Page</h1>
      <p>This is the Home Page</p>
    </div>
  );
}

export default Home;