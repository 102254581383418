import React from "react";
import { Helmet } from "react-helmet";

function Login() {
  return (
    <div className="login container">
      <Helmet>
          <base href="/"></base>
          <link href="login.css" type="text/css"></link>
        {/* <link rel="stylesheet" type="text/css" href="login.css" /> */}
      </Helmet>
      <h1>Login</h1>
      <p>This is the login page</p>
    </div>
  );
}

export default Login;